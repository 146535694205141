export interface UserInterface {
  id?: number;
  email: string;
  fname: string;
  lname: string;
  avatar: string;
  national_code: string;
  birthday: string;
  mobile: string;
  gender: string;
}

export class UserDTO implements UserInterface {
  id?: number;
  avatar = "";
  national_code = "";
  birthday = "";
  mobile = "";
  gender = "";
  email = "";
  fname = "";
  lname = "";
}

export default class UserModel extends UserDTO {
  constructor(dto: UserDTO) {
    super();
    Object.assign(this, dto);
  }

  get fullName(): string {
    return `${this.fname} ${this.lname}`;
  }

  get fullAvatar(): string {
    if (process.env.NODE_ENV == "development")
      return this.avatar ? `${this.avatar}` : require("@/assets/avatar.png");
    return this.avatar
      ? `${this.avatar}`
      : require("@/assets/avatar.png");
  }
}
