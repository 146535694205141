

































































































import { Component, Vue, Watch } from "vue-property-decorator";
import store from "../../../store";
import { AxiosResponse } from "axios";
import Enum from "@/config/enum";
import { ReportApi } from "@/networks/ReportApi";
import BarChart from "@/views/components/charts/BarChart";
import Period from "@/views/components/inputs/period.vue";
import UserModel, { UserDTO } from "@/models/UserModel";
import Count from "@/views/components/inputs/count.vue";

@Component({
  components: {
    BarChart: BarChart,
    period: Period,
    count: Count
  }
})
export default class General extends Vue {
  private selectedPeriod = "-30 days";
  private selectedCount = 5;
  private options = Enum.ChartOptions;
  private mainEvent = {
    labels: [],
    datasets: []
  };
  private topAdvisers = [];
  private topCall = [];

  mounted() {
    this.dashboard();
  }

  public async dashboard(): Promise<void> {
    try {
      const res: AxiosResponse = await new ReportApi().bests({
        period: this.selectedPeriod,
        limit: this.selectedCount
      });
      this.topAdvisers = res.data.data.top_advisers.map(
        (x: { cnt: number; creator: UserDTO }) => {
          return {
            cnt: x.cnt,
            user: new UserModel(x.creator)
          };
        }
      );
      this.topCall = res.data.data.top_calls.map(
        (x: { cnt: number; mentor: UserDTO }) => {
          return {
            cnt: x.cnt,
            user: new UserModel(x.mentor)
          };
        }
      );
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  @Watch("selectedPeriod")
  public onSelectedPeriodChange(val: string, oldVal: string): void {
    if (val != oldVal) {
      this.dashboard();
    }
  }

  @Watch("selectedCount")
  public onSelectedCountChange(val: string, oldVal: string): void {
    if (val != oldVal) {
      this.dashboard();
    }
  }
}
