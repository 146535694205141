


































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { VSelect, VBtn } from "vuetify/lib";
import Enum from "@/config/enum";

@Component({
  components: {
    VSelect,
    VBtn
  }
})
export default class Count extends Vue {
  @Prop({ default: 5 })
  defaultValue = 5;
  private period = Enum.count;
  private selectedPeriod = this.defaultValue;

  @Watch("selectedPeriod")
  public onPageChanged() {
    this.$emit("change-value", this.selectedPeriod);
  }
}
